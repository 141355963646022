import { useState } from 'react';
import apiClient, { handleError } from '../../../services/apiClientService';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../routes/RouteConstants';

const CANCEL_BOOKING_DASHBOARD_ENDPOINT = '/v1/dashboard/cancel/';

const useCancelBookingDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const cancelBookingDashboard = async (bookingId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiClient.post(CANCEL_BOOKING_DASHBOARD_ENDPOINT + bookingId);
      setData(response.data);
    } catch (error) {
      handleError(error, setError, navigate, Routes.DASHBOARD);
    } finally {
      setLoading(false);
    }
  };

  return { cancelBookingDashboard, loading, error, data };
};

export default useCancelBookingDashboard;
