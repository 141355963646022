import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  TextField,
  Typography,
  Stack,
} from '@mui/material';
import useCompleteRegistration from '../api/useCompleteRegistration';
import Page from '../../../components/Page';
import CustomSnackbar from '../../../components/CustomSnackbar';
import CustomButton from '../../../components/CustomButton';
import Section from '../../../components/Section';
import Loading from '../../../components/Loading';

const validationSchema = yup.object({
  name: yup.string().required("A név megadása kötelező!"),
  phoneNumber: yup
    .string()
    .matches(/^[0-9]+$/, 'A telefonszám helytelen!')
    .required("A telefonszám megadása kötelező!"),
});

const CompleteRegistrationLayout = () => {
  const { completeRegistration, loading, error } = useCompleteRegistration();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (error) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  }, [error]);

  const formik = useFormik({
    initialValues: {
      name: '',
      phoneNumber: '',
    },

    validationSchema: validationSchema,

    onSubmit: (values) => {
      completeRegistration(values); 
    },
  });

  return (
    <>
      <CustomSnackbar 
        open={snackbarOpen} 
        setOpen={setSnackbarOpen}
        message={snackbarMessage}/>
      
      <Page>
        <Section title="Regisztráció kiegészítés" titleVariant="h5" minWidth={{ xs: "70vw", sm: "60vw", md: "50vw", lg: "40vw", xl: "30vw" }}>
          <Typography variant="h6" textAlign="center" fontWeight="bold" mb={4}></Typography>

          {(loading ) && <Loading />}

          {!loading && !error && 
            <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
              <Stack spacing={3}>
                <TextField
                  sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "70%", xl: "60%" }, alignSelf: "center" }}
                  id="name"
                  name="name"
                  label="Név"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <TextField
                  sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "70%", xl: "60%" }, alignSelf: "center" }}
                  id="phoneNumber"
                  name="phoneNumber"
                  label="Telefonszám"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
                <CustomButton text="Tovább" type="submit" />     
              </Stack>
            </form>
          }
        </Section>
      </Page>
    </>
  );
};

export default CompleteRegistrationLayout;
