import dayjs from 'dayjs';
import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';

const CustomDateTimePicker = (props) => {
    const {
        date = dayjs(),
        setDate = () => null,
        label = "",
        views = defaultViews,
        ampm = false,
        format = defaultForamt } = props;


    return (
        <DateTimePicker
            label={label}
            value={date}
            onChange={(date) => setDate(date)}
            views={views}
            ampm={ampm}
            format={format} />
    );
};

const defaultViews = ['year', 'month', 'day', 'hours', 'minutes'];
const defaultForamt = 'YYYY.MM.DD. HH:mm';

export default CustomDateTimePicker;