import React from 'react';
import { Box, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

const MenuItem = ({ to, label, componentType: Component = NavLink }) => {

  return (
    <Component 
      to={to} 
      style={{ textDecoration: 'none', color: "black" }} 
      smooth
    >
      <Box
        sx={{
          '&:hover': {
            backgroundColor: theme => theme.palette.action.hover,
            color: theme => theme.palette.primary.main
          },
          p: 2
        }}
      >
        <Typography >
          {label}
        </Typography>
      </Box>
    </Component>
  );
};

export default MenuItem;
