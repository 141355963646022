import axios from 'axios';
import API_BASE_URL from '../config/config';
import { StorageKeys } from "../services/constants/StorageKeys";
import { storage } from "../services/storageService";
import { Routes } from '../routes/RouteConstants';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

export default apiClient;

export const handleError = (error, setError, navigate, targetRouteBeforeRedirect) => {
  if (error.code === "ERR_NETWORK") {
    setError({ message: "Sajnos a szerver jelenleg nem elérhető. Foglalj időpontot telefonon keresztül. 06 30 452 8948" });
    return;
  }  
  
  if (navigate && targetRouteBeforeRedirect) {
    if (error?.response?.status === 401) {
      console.log("Request is unauthenticated, redirect needed to login route");

      storage.set(StorageKeys.IS_USER_AUTHENTICATED, false)
      storage.set(StorageKeys.TARGET_ROUTE_BEFORE_REDIRECT, targetRouteBeforeRedirect);

      navigate(Routes.LOGIN);
      return;
    } else if (error?.response?.data?.code === "DEC-0006") {
      console.log("User is not verified, redirect needed to complete-registration route");

      storage.set(StorageKeys.TARGET_ROUTE_BEFORE_REDIRECT, targetRouteBeforeRedirect);

      navigate(Routes.COMPLETE_REGISTRATION);
      return;
    }
  }

  if (error?.response?.data?.message) {
    setError({ message: error.response.data.message });
    return;
  }  

  setError({ message: "Ismeretlen hiba történt, próbáld meg később vagy foglalj időpontot telefonon keresztül.  06 30 452 8948 (Error code: " + error.code + ")" });
};
