import dayjs from "dayjs";

export function getTimeFromDate(date) {
    if (!dayjs.isDayjs(date)) {
        return '';
    }

    return `${date.format('HH:mm')}`;
}


export function getDateFromDate(date) {
    if (!dayjs.isDayjs(date)) {
        return '';
    }

    return `${date.format('YYYY-MM-DD')}`;
}


export function getFormattedDate(date) {
    if (!dayjs.isDayjs(date)) {
        return '';
    }

    return `${date.format('YYYY.MM.DD.')}`;
}