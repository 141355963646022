import { Paper, Typography } from "@mui/material";

const Section = ({
  width, 
  minWidth,
  maxWidth,
  xsHorizontalPadding = 5,
  title,
  titleColor = "secondary",
  titleVariant = "h4",
  alignItems = "center",
  children,
}) => {

  return (
    <Paper 
      elevation={3}
      sx={{ 
        width: width,
        minWidth: minWidth,
        maxWidth: maxWidth,
        borderRadius: 4, 
        my: 3, 
        py: 5,
        px: { xs: xsHorizontalPadding, sm: 5 }, 
        display: "flex", 
        flexDirection: "column", 
        alignItems: alignItems, 
      }}>
        {title && 
          <Typography 
            alignSelf="center"
            variant={titleVariant}
            fontWeight="bold"
            noWrap 
            mb={4}
            color={titleColor} 
          > 
            {title} 
          </Typography>
        }

        {children}   
    </Paper>
  )
}

export default Section
