export const Routes = {
  OPENING_HOURS: "/#openingHours",
  CONTACTS: "/#contacts",
  SUPPLY: "/supply",
  BOOKING: "/booking",
  LOGIN: "/login",
  COMPLETE_REGISTRATION: "/complete-registration",
  SUCCESSFUL_AUTH: "/successful-auth",
  PRE_RESERVE: "/pre-reserve",
  CONFIRM_BOOKING: "/confirm-booking",
  SUCCESSFUL_BOOKING: "/successful-booking",
  PROFILE: "/profile",
  LOGOUT: "/logout",
  PRIVAY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  ERROR: "/error",
  DASHBOARD: "/dashboard"
};