import { createTheme } from '@mui/material/styles';
import { huHU as datePickerHu } from '@mui/x-date-pickers/locales';
import { huHU as coreHu } from '@mui/material/locale';
const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#95570b",
        light: "#C9A983",
      },
      secondary: {
        main: "#000000",
        light: "#323232"
      },
      action: {
        main: "#808080",
        light: "#C0C0C0",
      },
      whiteButton: {
        main: "#FFFFFF",
        light: "#F0F0F0",
      },
    },
  },
  datePickerHu,
  coreHu
);

export default theme;
