export const isEmbeddedBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
  const embeddedBrowsers = [
    'FBAN',     // Facebook App
    'FBAV',     // Facebook In-App Browser
    'Instagram', // Instagram In-App Browser
    'Snapchat',  // Snapchat In-App Browser
  ];

  return embeddedBrowsers.some(keyword => userAgent.includes(keyword));
};

export default isEmbeddedBrowser;
