import { useState } from 'react';
import apiClient, { handleError } from '../../../services/apiClientService';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../routes/RouteConstants';

const POST_MANUAL_BOOKING_ENDPOINT = '/v1/dashboard/manualBooking';

const useManualBooking = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const postManualBooking = async (requestData) => {
    setLoading(true);
    setError(null);

    var requestBody = {
      supplyId: requestData?.supplyId,
      date: requestData?.date,
      startTime: requestData?.startTime,
      guest: requestData?.name
    };

    try {
      const response = await apiClient.post(POST_MANUAL_BOOKING_ENDPOINT, requestBody);
      setData(response.data);
    } catch (error) {
      handleError(error, setError, navigate, Routes.DASHBOARD);
    } finally {
      setLoading(false);
    }
  };

  return { postManualBooking, loading, error, data };
};

export default useManualBooking;
