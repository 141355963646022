import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Page from "../../../components/Page";
import Section from "../../../components/Section";
import CustomButton from '../../../components/CustomButton';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../routes/RouteConstants';

const SuccessFulBookingLayout = () => {
  const navigate = useNavigate();

  return (
    <Page>
      <Section title="Sikeres időpontfoglalás" titleVariant="h5" minWidth={{ xs: "70vw", md: "60vw", lg: "40vw", xl: "30vw" }}>
        <CheckCircleIcon sx={{ fontSize: 60, color: 'green', mb: 3 }} />
        <CustomButton variant="outlined" text="Foglalás megtekintése" onClick={() => navigate(Routes.PROFILE)} mt={1} />
      </Section>
    </Page>
  );
};

export default SuccessFulBookingLayout;
