import React from 'react';
import Page from '../components/Page';
import Section from '../components/Section';
import { Typography, Link } from '@mui/material';

const PrivacyPolicyLayout = () => {
  return (
    <Page>
      <Section title="Adatkezelési Nyilatkozat" titleVariant="h5" alignItems="start">
        <Typography variant="h5" my={3}>
          1. Bevezetés
        </Typography>
        <Typography paragraph>
          Ez az adatvédelmi tájékoztató leírja, hogyan gyűjtjük, használjuk és védjük az Ön személyes adatait, amikor a 
          <Link href="https://brownsbs.hu" target="_blank" rel="noopener noreferrer"> https://brownsbs.hu</Link> weboldalt használja, 
          amelyen keresztül időpontot foglalhat a szolgáltatást nyújtó vállalkozó szolgáltatásaira.
        </Typography>

        <Typography variant="h5" my={3}>
          2. Az Adatkezelő Adatai
        </Typography>
        <Typography paragraph>
          Név: Barna Ádám <br />
          Székhely: Nyírtelek Iskola út 5<br />
          E-mail: adam19980525@gmail.com <br />
          Telefonszám: 06 30 452 8948 <br />
          Nyílvántartási szám: 58126464 <br />
          Adószám: 41887473-1-35 <br />
        </Typography>

        <Typography variant="h5" my={3}>
          3. Gyűjtött Adatok Típusa
        </Typography>
        <Typography paragraph>
          Az alábbi személyes adatokat gyűjtjük a felhasználók időpontfoglalásai és hitelesítése során: <br />
          • E-mail cím <br />
          • Név <br />
          • Telefonszám <br />
          • Foglalt időpont dátuma, kezdési és befejezési időpontja, időtartama <br />
          • A foglalt szolgáltatás jellege <br />
          <br />
          Az e-mailes hitelesítés a Google OAuth2 szolgáltatáson keresztül történik. A weboldal cookie-t használ az autentikációhoz szükséges hozzáférési token tárolására.
        </Typography>

        <Typography variant="h5" my={3}>
          4. Adatkezelés Célja
        </Typography>
        <Typography paragraph>
          • A szolgáltatás nyújtásához szükséges adatok kezelése <br />
          • A felhasználókkal való kommunikáció a foglalások megerősítése céljából <br />
          • Szolgáltatásaink fejlesztése és javítása a felhasználói visszajelzések alapján 
        </Typography>

        <Typography variant="h5" my={3}>
          5. Az Adatkezelés Jogi Alapja
        </Typography>
        <Typography paragraph>
          Az adatkezelés jogalapja a felhasználó hozzájárulása, amelyet a weboldal használatával ad meg, valamint az adatkezelés szükségessége az időpontfoglalási szolgáltatás nyújtása érdekében.
        </Typography>

        <Typography variant="h5" my={3}>
          6. Adatok Megosztása és Nyilvánosságra Hozatala
        </Typography>
        <Typography paragraph>
          Az Ön személyes adatait harmadik féllel (Google) kizárólag az e-mailes hitelesítés céljából osztjuk meg. Az adatokat a szolgáltatást nyújtó személy láthatja, mivel az időpontfoglalások kezelése és adminisztrációja érdekében hozzáférése van a foglalások során megadott személyes adatokhoz (név, e-mail cím, telefonszám, a foglalt időpont dátuma, kezdési és befejezési időpontja, időtartama, a foglalt szolgáltatás jellege). Ezen adatokhoz kizárólag a szolgáltatást nyújtó vállalkozó fér hozzá a szolgáltatás nyújtása céljából, valamint az adatfeldolgozó karbantartás céljából.
        </Typography>

        <Typography variant="h5" my={3}>
          7. Adatmegőrzési Időszak
        </Typography>
        <Typography paragraph>
          Az Ön személyes adatait a weboldal működéséig, vagy a törlési kérelem beérkezéséig, de legfeljebb 5 évig őrizzük meg.
        </Typography>

        <Typography variant="h5" my={3}>
          8. Felhasználói Jogok
        </Typography>
        <Typography paragraph>
          Önnek jogában áll: <br />
          • Hozzáférést kérni az Önről tárolt adatokhoz. <br />
          • Az adatok helyesbítését kérni. <br />
          • Az adatok törlését kérni. <br />
          • Az adatkezelés korlátozását kérni. <br />
          • Tiltakozni az adatkezelés ellen. <br />
          <br />
          Ezen jogait gyakorolhatja a fenti elérhetőségeken keresztül. Ha Ön úgy véli, hogy az adatkezelés során jogsértés történt, joga van panaszt tenni a Nemzeti Adatvédelmi és Információszabadság Hatóságnál (NAIH). A NAIH elérhetőségei: <br />
          • Cím: 1055 Budapest, Szalay utca 10-14. <br />
          • Weboldal: <Link href="https://naih.hu" target="_blank" rel="noopener noreferrer">https://naih.hu</Link>
        </Typography>

        <Typography variant="h5" my={3}>
          9. Adatbiztonság
        </Typography>
        <Typography paragraph>
          Az Ön adatait biztonságos, titkosított kapcsolat (HTTPS) használatával védjük. Továbbá a Google OAuth2 hitelesítési szolgáltatása biztosítja a biztonságos belépést. Az adatokat kizárólag a szolgáltatást nyújtó személy számára tesszük elérhetővé, aki szintén a Google OAuth2 rendszerén keresztül jelentkezik be.
        </Typography>

        <Typography variant="h5" my={3}>
          10. Az Adatvédelmi Tájékoztató Módosítása
        </Typography>
        <Typography paragraph>
          Fenntartjuk a jogot az adatvédelmi tájékoztató módosítására. A változásokat a weboldalon tesszük közzé, és a módosított tájékoztató a közzétételt követően azonnal hatályba lép. A felhasználóknak ajánlott rendszeresen ellenőrizniük az adatvédelmi tájékoztatót a legfrissebb információkért. A weboldal használatával a felhasználók elfogadják a módosításokat.
        </Typography>

        <Typography variant="h5" my={3}>
          11. Adatfeldolgozó
        </Typography>
        <Typography paragraph>
          A weboldal technikai üzemeltetését Kovács Levente végzi mint adatfeldolgozó. Az adatfeldolgozó a felhasználók adatait kizárólag a weboldal technikai működtetése és karbantartása céljából kezeli, és nem fér hozzá az adatokhoz, kivéve a technikai karbantartás során. Az adatkezelés minden más vonatkozásában az adatkezelő felelős.
        </Typography>
        <Typography paragraph>
          E-mail: kovacs.levente071@gmail.com
        </Typography>
      </Section>
    </Page>
  );
};

export default PrivacyPolicyLayout;
