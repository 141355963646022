import { useState, useEffect } from "react";
import {
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StorageKeys } from "../../../services/constants/StorageKeys";
import { storage } from "../../../services/storageService";
import CustomSnackbar from "../../../components/CustomSnackbar";
import Page from '../../../components/Page';
import useConfirmBooking from "../api/useConfirmBooking";
import Section from "../../../components/Section";
import CustomButton from "../../../components/CustomButton";
import SelectedSupply from "../../../components/SelectedSupply";
import BorderedBox from "../../../components/BorderedBox";
import Loading from "../../../components/Loading";
import { Routes } from "../../../routes/RouteConstants";

const ConfirmBookingLayout = () => {
  const { confirmBooking, loading, error } = useConfirmBooking();
  const selectedSupply = storage.get(StorageKeys.SELECTED_SUPPLY);
  const selectedAppointment = storage.get(StorageKeys.SELECTED_APPOINTMENT);
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  
  useEffect(() => {
    if (error) {
      setSnackbarMessage(error.message);
      setSnackbarOpen(true);
    }
  }, [error]);

  const handleBookingConfirmation = async () => {
    const preReservedBookingId =  storage.get(StorageKeys.PRE_RESERVED_BOOKING_ID);
    
    const response = await confirmBooking(preReservedBookingId);
    if (!response) return;
    
    storage.remove(StorageKeys.SELECTED_SUPPLY);
    storage.remove(StorageKeys.SELECTED_APPOINTMENT);
    storage.remove(StorageKeys.PRE_RESERVED_BOOKING_ID);
    navigate(Routes.SUCCESSFUL_BOOKING);
  };

  return (
    <>
      <CustomSnackbar open={snackbarOpen} setOpen={setSnackbarOpen} message={snackbarMessage}/>

      <Page>
        <Section title="Foglalás véglegesítése" titleVariant="h5" minWidth={{ xs: "70vw", sm: "60vw", md: "50vw", lg: "40vw", xl: "30vw" }}>
          {loading && <Loading />}

          {!loading && !error && ( 
            <>
              <Stack spacing={2} minWidth="100%" mt={2} mb={5}>
                <SelectedSupply title="Szolgáltatás" supply={selectedSupply} fixedWidth={false} />
              
                <BorderedBox title = "Időpont">
                  <Typography fontWeight="bold">{selectedAppointment.date.replaceAll("-", ".")}</Typography>
                  <Typography color="text.primary">{selectedAppointment.time}</Typography>
                </BorderedBox>
              </Stack>

              <CustomButton text="Véglegesítés" onClick={handleBookingConfirmation}/>
            </>
          )}
        </Section>
      </Page> 
    </>
  );
};

export default ConfirmBookingLayout;
