import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import MainLayout from '../layouts/main/MainLayout';
import HomeLayout from '../features/home/pages/HomeLayout'; 
import SupplyLayout from '../features/supply/pages/SupplyLayout';
import ProfileLayout from '../features/user/pages/ProfileLayout';
import BookingLayout from '../features/booking/pages/BookingLayout';
import ConfirmBookingLayout from '../features/booking/pages/ConfirmBookingLayout';
import LoginLayout from '../features/auth/pages/LoginLayout';
import SuccessfulAuthLayout from '../features/auth/pages/SuccessfulAuthLayout';
import ErrorLayout from '../pages/ErrorLayout';
import CompleteRegistrationLayout from '../features/auth/pages/CompleteRegistrationLayout';
import LogoutLayout from '../features/auth/pages/LogoutLayout';
import { Routes } from "./RouteConstants";
import { ProtectedBookingRoute, ProtectedPrePreserveRoute, ProtectedConfirmBookingRoute, ProtectedProfileRoute, ProtectedDashboardRoute } from './ProtectedRoutes';
import DashboardLayout from '../layouts/DashboardLayout';
import PreReserveLayout from '../features/booking/pages/PreReserveLayout';
import SuccesfulBookingPage from '../features/booking/pages/SuccessfulBookingLayout';
import PrivacyPolicyLayout from '../pages/PrivacyPolicyLayout';
import TermsAndConditionsLayout from '../pages/TermsAndConditionsLayout';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route >
      <Route path={Routes.DASHBOARD} element={
        <ProtectedDashboardRoute>
          <DashboardLayout />
        </ProtectedDashboardRoute>
      } />

      <Route path='/' element={<MainLayout />} >
        {/* Home */}
        <Route index element={<HomeLayout />} />

        {/* Supply */}
        <Route path={Routes.SUPPLY} element={<SupplyLayout />} />

        {/* Booking */}
        <Route path={Routes.BOOKING} element={
          <ProtectedBookingRoute>
            <BookingLayout />
          </ProtectedBookingRoute> 
        } />

        {/* Login */}
        <Route path={Routes.LOGIN} element={<LoginLayout />} />

        {/* Pre reserve */}
        <Route path={Routes.PRE_RESERVE} element={
          <ProtectedPrePreserveRoute>
            <PreReserveLayout />
          </ProtectedPrePreserveRoute>
        } />

        {/* Confirm booking */}
        <Route path={Routes.CONFIRM_BOOKING} element={
          <ProtectedConfirmBookingRoute>
            <ConfirmBookingLayout />
          </ProtectedConfirmBookingRoute>
        } />

        {/* Successful booking */}
        <Route path={Routes.SUCCESSFUL_BOOKING} element={<SuccesfulBookingPage /> } />

        {/* Profile */}
        <Route path={Routes.PROFILE} element={
          <ProtectedProfileRoute>
            <ProfileLayout />
          </ProtectedProfileRoute>
        } />

        {/* Documents */}
        <Route path={Routes.PRIVAY_POLICY} element={<PrivacyPolicyLayout />} />
        <Route path={Routes.TERMS_AND_CONDITIONS} element={<TermsAndConditionsLayout />} />

        {/* Logout */}
        <Route path={Routes.LOGOUT} element={<LogoutLayout />} />

        {/* Backend Redirects */}
        <Route path={Routes.COMPLETE_REGISTRATION} element={<CompleteRegistrationLayout />} />
        <Route path={Routes.SUCCESSFUL_AUTH} element={<SuccessfulAuthLayout />} />
        <Route path={Routes.ERROR} element={<ErrorLayout />} />
      </Route>
    </Route>
  )
);

export default router;
