import { Box, Modal, Typography, Stack } from '@mui/material';
import React from 'react';

const CustomModal = (props) => {
  const {
    open = false,
    onClose = () => null,
    title = "",
    titleId = "modal-title",
    style = defaultStyle
  } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}>
      <Box sx={style}>
        <Stack spacing={3}>
          <Typography id={titleId} variant="h5" fontWeight="bold" align="center">
            {title}
          </Typography>

          {props.children}
        </Stack>
      </Box>
    </Modal >
  );
};

const defaultStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default CustomModal;