import { useState } from 'react';
import apiClient, { handleError } from '../../../services/apiClientService';
import { StorageKeys } from "../../../services/constants/StorageKeys";
import { storage } from "../../../services/storageService";
import { useNavigate } from "react-router-dom";
import { Routes } from '../../../routes/RouteConstants';

const GET_USER_ENDPOINT = '/v1/user/getUser';

const useGetUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  
  const getUser = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiClient.get(GET_USER_ENDPOINT);
      setData(response.data);
    } catch (error) {
      handleError(error, setError, navigate, Routes.PROFILE);
    } finally {
      setLoading(false);
    }
  };

  return { getUser, loading, error, data };
};

export default useGetUser;
