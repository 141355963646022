import React from 'react';
import { Stack, Typography } from '@mui/material';

const OpeningHoursItem = ({ day, hours }) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ p: 2 }}
  >
    <Typography fontSize={24} fontWeight="medium" color={"primary"} >{day}</Typography>
    <Typography>{hours}</Typography>
  </Stack>
);

export default OpeningHoursItem;