import { useState } from 'react';
import apiClient, { handleError } from '../../../services/apiClientService';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../routes/RouteConstants';

const BLOCK_APPOINTMENT_ENDPOINT = '/v1/dashboard/blockPeriod';

const useBlockPeriod = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const blockPeriod = async (requestData) => {
    setLoading(true);
    setError(null);

    const requestBody = {
      startDate: requestData?.startDate,
      endDate: requestData?.endDate,
      startTime: requestData?.startTime,
      endTime: requestData?.endTime,
    };

    try {
      const response = await apiClient.post(BLOCK_APPOINTMENT_ENDPOINT, requestBody);
      setData(response.data);
    } catch (error) {
      handleError(error, setError, navigate, Routes.DASHBOARD);
    } finally {
      setLoading(false);
    }
  };

  return { blockPeriod, loading, error, data };
};

export default useBlockPeriod;
