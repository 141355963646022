import { useState } from 'react';
import apiClient, { handleError } from '../../../services/apiClientService';
import { StorageKeys } from "../../../services/constants/StorageKeys";
import { storage } from "../../../services/storageService";
import { useNavigate } from "react-router-dom";
import { Routes } from '../../../routes/RouteConstants';

const CONFIRM_BOOKING_ENDPOINT = '/v1/booking/confirm';

const useConfirmBooking = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const preReservedBookingId = storage.get(StorageKeys.PRE_RESERVED_BOOKING_ID);

  const confirmBooking = async () => {
    setLoading(true);
    setError(null);

    try {
      const url = CONFIRM_BOOKING_ENDPOINT + "/" + preReservedBookingId;
      await apiClient.post(url);
      return true;
    } catch (error) {
      handleError(error, setError, navigate, Routes.CONFIRM_BOOKING);   
    } finally {
      setLoading(false);
    }
  };

  return { confirmBooking, loading, error };
};

export default useConfirmBooking;
