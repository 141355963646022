import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../components/CustomButton';
import CustomModal from './CustomModal';
import dayjs from 'dayjs';
import { getDateFromDate, getTimeFromDate } from '../../../utils/dateUtils';
import CustomDateTimePicker from '../../../components/CustomDateTimePicker';
import useBlockPeriod from '../api/useBlockPeriod';

const BlockTimeModal = (props) => {
    const { show, handleClose, showSnackBarMessage = () => null, reloadSignal = () => null } = props;
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const { blockPeriod, error } = useBlockPeriod();

    const validateValues = () => {
      return startDate.isBefore(endDate);
    };

    const handleBlockAppointment = async () => {
      if (!validateValues()) {
        console.log("Invalid values");
        return;
      }
      await blockPeriod({
        startDate: getDateFromDate(startDate), endDate: getDateFromDate(endDate),
        startTime: getTimeFromDate(startDate), endTime: getTimeFromDate(endDate)
      });
      if (!error) {
        showSnackBarMessage("Szabadnap rögzítve", "success");
        reloadSignal();
        handleClose();
      }
    };

    useEffect(() => {
      if (error) {
        showSnackBarMessage(error.message);
      }
    }, [error]);

    return (
      <CustomModal
        open={show}
        onClose={handleClose}
        title="Inaktív időszak beállitása">
        <Stack spacing={3}>
          <CustomDateTimePicker label="Mettöl" date={startDate} setDate={setStartDate} />
          <CustomDateTimePicker label="Meddig" date={endDate} setDate={setEndDate} />
          <CustomButton color="primary" text="Rögzítés" onClick={handleBlockAppointment} />
        </Stack>
      </CustomModal>
    );
};

export default BlockTimeModal;