import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const CustomButton = ({
  variant = 'contained',
  color = 'primary',
  onClick,
  text = null,
  textTransform = 'capitalize',
  mt = 0,
  type = "button",
  fullwidth = false,
  textColor = null,
  icon = null
}) => {
  return (
    <Box display="flex" justifyContent="center" mt={mt}>
      <Button variant={variant} color={color} onClick={onClick} type={type} fullWidth={fullwidth}>
        <Typography fontWeight="bold" textTransform={textTransform} color={textColor}>
          {icon}
          {text?.split("<br />").map(t => 
            <>
              {t} <br />
            </>)}
        </Typography>
      </Button>
    </Box>
  );
};

export default CustomButton;
