import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom'; 
import { StorageKeys } from "../../../services/constants/StorageKeys";
import { storage } from "../../../services/storageService";
import Page from '../../../components/Page';
import Section from "../../../components/Section";
import Loading from '../../../components/Loading';

const LogoutLayout = () => {
  const navigate = useNavigate();
  const { forceHeaderReRender } = useOutletContext();

  useEffect(() => {
    storage.set(StorageKeys.IS_USER_AUTHENTICATED, false);
    navigate("/");
    forceHeaderReRender();
  }, []);

  return (
    <Page>
      <Section title="Kijelentkezés"  minWidth={{ xs: "70vw", sm: "60vw", md: "50vw", lg: "40vw", xl: "30vw" }}>
        <Loading />
      </Section>
    </Page>
  );
}

export default LogoutLayout
