import Header from './components/Header';
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';
import { Stack } from "@mui/material";
import CustomSnackbar from '../../components/CustomSnackbar';
import isEmbeddedBrowser from '../../utils/embeddedBrowserChecker';
import React, { useState, useEffect } from 'react';

export default function RootLayout() {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [headerKey, setHeaderKey] = useState(0); 
  
  const forceHeaderReRender = () => {
    setHeaderKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    setIsSnackbarOpen(isEmbeddedBrowser());
  }, []);

  return (
    <Stack>
      <CustomSnackbar 
        open={isSnackbarOpen} 
        setOpen={setIsSnackbarOpen}
        message="Ha messenger-ből vagy hasonló app-ból nyitod meg az alkalmazást előfordulhat, hogy nem minden funkció működik megfelelően. Másold át a linket egy böngészőbe!"
        severity="warning"
        mt={7} 
      />
      <Header key={headerKey} />
      <Outlet context={{ forceHeaderReRender }}/>
      <Footer />
    </Stack>
  )
}
