import React from 'react'
import { CircularProgress } from '@mui/material'

const Loading = ({ size = 72, color = "primary" }) => {
  return (
    <CircularProgress size={size} sx={{ my: 5 }} color={color} />
  )
}

export default Loading
