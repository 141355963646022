import { useState } from 'react';
import apiClient, { handleError } from '../../../services/apiClientService';
import { useNavigate } from "react-router-dom";
import { Routes } from '../../../routes/RouteConstants';

const PRE_RESERVE_ENDPOINT = '/v1/booking/pre-reserve';

const usePreReserve = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  const preReserve = async (requestData) => {
    setLoading(true);
    setError(null);

    try {
      const response = await apiClient.post(PRE_RESERVE_ENDPOINT, requestData);
      validateResponse(response);
      return response.data.id;
    } catch (error) {
      handleError(error, setError, navigate, Routes.PRE_RESERVE);   
    } finally {
      setLoading(false);
    }
  };

  return { preReserve, loading, error };
};

const validateResponse = (response) => {
  if (!response) {
    console.error("No response from preReserve");
    return;
  }

  if (!response.data) {
      console.error("Response data is missing:", response);
      return;
  }

  if (!response.data.id) {
      console.error("PreReserve response missing ID:", response.data);
      return;
  }
};

export default usePreReserve;
