import { Box, Card, Grid, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import Page from '../components/Page';
import ManualBookingModal from '../features/dashboard/modals/ManualBookingModal';
import BlockTimeModal from '../features/dashboard/modals/BlockTimeModal';
import useGetSupplies from '../features/supply/api/useGetSupplies';
import useGetDailySchedules from '../features/dashboard/api/useGetDailySchedules';
import dayjs from 'dayjs';
import { getDateFromDate } from '../utils/dateUtils';
import CustomButton from '../components/CustomButton';
import BookingDetailsModal from '../features/dashboard/modals/BookingDetailsModal';
import CustomSnackbar from '../components/CustomSnackbar';

export default function DashboardLayout() {
    const [supplyId, setsupplyId] = useState("");
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [showBlockTimeModal, setShowBlockTimeModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [loadedSchedules, setLoadedSchedules] = useState([]);
    const [showBookingModal, setShowBookingModal] = useState(false);
    const [bookingId, setBookingId] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("error");
    const [reloadSignal, setReloadSignal] = useState(false);
    const { getSupplies, data: suppliesData } = useGetSupplies();
    const { getDailySchedules, data: dailySchedulesData } = useGetDailySchedules();

    useEffect(() => {
        getSupplies();
    }, []);

    useEffect(() => {
      if (dailySchedulesData && dailySchedulesData.length > 0) {
        setLoadedSchedules(schedules => [...schedules, ...dailySchedulesData]);
      }
    }, [dailySchedulesData]);

    useEffect(() => {
      if (supplyId && supplyId !== "") {
        const date = dayjs();
        getDailySchedules({ startDate: getDateFromDate(date), supplyId: supplyId });
        //reset schedules to 0
        setLoadedSchedules([]);
      }
    }, [supplyId]);

    useEffect(() => {
      setLoadedSchedules(_ => {
        getDailySchedules({ startDate: getDateFromDate(dayjs()), supplyId: supplyId });
        return [];
      });
    }, [reloadSignal]);

    const showSnackBarMessage = (message, severity = "error") => {
      setSnackbarSeverity(_ => {
          setSnackbarMessage(_ => {
              setSnackbarOpen(true);
              return message;
          })
          return severity;
      })
    }

    const loadMoreSchedules = () => {
      const nextDay = dayjs(dailySchedulesData[dailySchedulesData.length - 1].date).add(1, 'day');
      getDailySchedules({ startDate: getDateFromDate(nextDay), supplyId: supplyId });
    }

    const renderTimeSlot = (date, time) => {
      if (!time || !date) {
        return <>  </>;
      }

      if (time.available !== true) {
        return (
          <CustomButton
            key={`${date}-${time.startTime}`}
            variant="outlined"
            color="primary"
            onClick={() => openBookedModal(time.id)}
            fullwidth={true}
            text={time?.guest + "<br />" + time?.startTime + " - " + time?.endTime} />
        );
      }

      return (
        <CustomButton
          key={`${date}-${time.startTime}`}
          variant="outlined"
          color="secondary"
          onClick={() => openAppointmentModal(date, time)}
          fullwidth={true}
          text={time.startTime} />
      );
    }

    const openAppointmentModal = (date, time) => {
      setModalData(() => {
        setShowAppointmentModal(true);
        return { date, time, supply: suppliesData.find(supply => supply.id === supplyId) };
      });
    }

    const openBlockTimeModal = () => {
        setShowBlockTimeModal(true);
    }

    const openBookedModal = (bookingId) => {
      setBookingId(_ => {
          setShowBookingModal(true);
          return bookingId;
      })
    }

    const handleReloadSignal = () => {
      setReloadSignal(old => !old);
    }

    const renderAppointments = () => {
      if (!loadedSchedules) {
        return <></>;
      }

      return (
        loadedSchedules.map(schedule => {
          const date = schedule.date;
          return (
            <Grid key={date} item xs={6} sm={6} md={4} lg={2}>
              <Card>
                <Stack direction={{ xs: "column", xl: "row" }} justifyContent="space-around" alignItems="center" p={1} bgcolor="black">
                  <Typography variant="h6" textTransform="capitalize" color="white" noWrap>
                    {schedule.weekday}
                  </Typography>

                  <Typography variant="body1" color="grey.200" noWrap fontSize={{ xs: 12, sm: 14, md: 16 }}>
                    {schedule.formattedDate}
                  </Typography>
                </Stack>

                <Box>
                  <Stack p={1} spacing={1}>
                      {schedule.timeSlots.map(time => (renderTimeSlot(date, time)))}
                  </Stack>
                </Box>
              </Card>
            </Grid>
          )
      }));
    }

    return (
      <>
        <Page>
          <Stack spacing={2} alignItems="center" width="100%">
            <Box width="300px">
              <TextField select={true} id="supply-select" label="Szolgáltatás"
                value={supplyId} variant="outlined" margin="normal" fullWidth={true}
                onChange={(event) => setsupplyId(event.target.value)}>
                {suppliesData?.map(supply => <MenuItem key={supply.id} value={supply.id}>{supply.name}</MenuItem>)}
              </TextField>
            </Box>

            <Box width="300px">
              <CustomButton color="primary" text="Inaktív időszak beállítás" onClick={openBlockTimeModal} fullwidth={true} />
            </Box>

            <Grid container spacing={1}>
              {renderAppointments()}
            </Grid>

            <Box width="300px">
              <CustomButton variant="outlined" color="primary" text="Tovább" onClick={loadMoreSchedules} fullwidth={true} />
            </Box>
          </Stack>
        </Page>

        <CustomSnackbar
            open={snackbarOpen}
            setOpen={setSnackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity} />

        <ManualBookingModal show={showAppointmentModal}
            modalData={modalData} handleClose={() => setShowAppointmentModal(false)}
            showSnackBarMessage={showSnackBarMessage} reloadSignal={handleReloadSignal} />

        <BlockTimeModal show={showBlockTimeModal} handleClose={() => setShowBlockTimeModal(false)}
            showSnackBarMessage={showSnackBarMessage} reloadSignal={handleReloadSignal} />

        <BookingDetailsModal show={showBookingModal} handleClose={() => setShowBookingModal(false)} bookingId={bookingId}
            showSnackBarMessage={showSnackBarMessage} reloadSignal={handleReloadSignal} />
      </>
    )
}