import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import MenuItem from './HeaderMenuItem';
import { Routes } from '../../../routes/RouteConstants';
import {
  AppBar,
  Button,
  Stack,
  Container,
  Box,
  useMediaQuery,
  useTheme,
  Divider,
  IconButton,
  Drawer,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { storage } from '../../../services/storageService';
import { StorageKeys } from '../../../services/constants/StorageKeys';

export default function Header() {
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isUserAuthenticated = storage.get(StorageKeys.IS_USER_AUTHENTICATED);

  const handleDrawerToggle = () => {
    setDrawerOpen((prevDrawerOpen) => !prevDrawerOpen);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: theme.palette.secondary.main, 
      //backgroundColor: { xs: "black", sm: "green", md: "blue", lg: "red", xl: "pink" } 
      }}>
      <Container maxWidth="xl" disableGutters={downMd}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box bgcolor={"white"}>
            <NavLink to="/" style={{ textDecoration: 'none', color: 'white' }}>
              <img
                src="/logo.png"
                alt="Company Logo"
                style={{ height: '70px' }}
              />
            </NavLink>
          </Box>

          {downMd ? (
            <IconButton color="inherit" onClick={handleDrawerToggle}>
              <MenuIcon fontSize="large" />
            </IconButton>
          ) : (
            <Stack
              direction="row"
              spacing={1}
              alignItems={"center"}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <NavLink index="true" style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Főoldal</Button>
              </NavLink>
              <NavLink to={Routes.BOOKING} style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Foglalás</Button>
              </NavLink>
              <NavLink to={Routes.SUPPLY} style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Szolgáltatások</Button>
              </NavLink>
              <HashLink smooth to={Routes.OPENING_HOURS} style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Nyitvatartás</Button>
              </HashLink>
              <HashLink smooth to={Routes.CONTACTS} style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Kapcsolatok</Button>
              </HashLink>
              <NavLink to={Routes.PROFILE} style={{ textDecoration: 'none', color: 'white' }}>
                <Button color="inherit" sx={{ fontWeight: 'bold' }}>Profil</Button>
              </NavLink>
              {
                isUserAuthenticated &&
                <NavLink to={Routes.LOGOUT} style={{ textDecoration: 'none', color: 'white' }}>
                  <Button color="inherit" sx={{ fontWeight: 'bold' }}>Kijelentkezés</Button>
                </NavLink>
              }
            </Stack>
          )}
        </Stack>
      </Container>

      <Drawer anchor={"right"} open={drawerOpen} onClose={handleDrawerToggle}>
        <Box
          sx={{ width: 250 }}
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
        >
          <Stack divider={<Divider orientation="horizontal" flexItem />}>
            <MenuItem index label="Főoldal" />
            <MenuItem to={Routes.BOOKING} label="Foglalás" />
            <MenuItem to={Routes.SUPPLY} label="Szolgáltatások" />
            <MenuItem to={Routes.OPENING_HOURS} label="Nyitvatartás" componentType={HashLink} smooth />
            <MenuItem to={Routes.CONTACTS} label="Kapcsolatok" componentType={HashLink} smooth />
            <MenuItem to={Routes.PROFILE} label="Profil" />
            { isUserAuthenticated && <MenuItem to={Routes.LOGOUT} label="Kijelentkezés" />}
          </Stack>
        </Box>
      </Drawer>
    </AppBar>
  );
}
