import { useState } from 'react';
import apiClient, { handleError } from '../../../services/apiClientService';
import { useNavigate } from "react-router-dom";
import { Routes } from '../../../routes/RouteConstants';

const CANCEL_BOOKING_ENDPOINT = '/v1/booking/cancel';

const useCancelBooking = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const cancelBooking = async (requestData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const url = CANCEL_BOOKING_ENDPOINT + "/" + requestData;
      await apiClient.post(url);
      setSuccess(true);
    } catch (error) {
      handleError(error, setError, navigate, Routes.PROFILE);
    } finally {
      setLoading(false);
    }
  };

  return { cancelBooking, loading, error, success };
};

export default useCancelBooking;
