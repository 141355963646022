import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const BorderedBox = ({ fixWidth, title, lable, children }) => {
  const theme = useTheme();

  return (
    <Box>
      {title && <Typography gutterBottom>{title}</Typography>}

      <Box
        sx={{
          p: 2,
          borderRadius: "5px",
          border: `1px solid ${theme.palette.primary.main}`,
          minWidth: fixWidth,
          maxWidth: fixWidth
        }}
      >
        { lable ? lable : children}
      </Box>
    </Box>
  );
};

export default BorderedBox;
