import React from 'react';
import { Box, Container, Stack, Typography, Link, IconButton, Divider, useMediaQuery, useTheme } from "@mui/material";
import { Facebook, Instagram, LocationOn, Email, Phone } from '@mui/icons-material';
import { NavLink } from "react-router-dom";
import { Routes } from '../../../routes/RouteConstants';

const iconButtonStyles = {
  backgroundColor: 'black',
  color: 'white',
  '&:hover': {
    backgroundColor: "rgb(60, 60, 60)",
  },
};

export default function Footer() {
  const theme = useTheme();
  const isLg = useMediaQuery(theme => theme.breakpoints.up('lg'));

  return (
    <Box sx={{ bgcolor: theme.palette.primary.light }}>
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap" overflow="hidden">
          <Stack
            id="contacts" 
            direction={{
              lg: "row"
            }}
            spacing={{ xs: 2, md: 3 }} 
            divider={isLg ? <Divider orientation="vertical" flexItem /> : <Divider orientation="horizontal" flexItem/>}
            alignItems={{ lg: "center" }}
            py={3}
          >
            <Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                <LocationOn />
                <Typography fontWeight="bold">Cím</Typography>
              </Stack>
              <Typography fontWeight="medium" noWrap>
                Nyírtelek, Iskola u. 5, 4461
              </Typography>
            </Box>
            <Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Email />
                <Typography fontWeight="bold">Email</Typography>
              </Stack>
              
              <Link display="block" href="mailto:adam19980525@gmail.com" color="inherit" fontWeight="medium" noWrap>
                adam19980525@gmail.com
              </Link>
            </Box>   
            <Box>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Phone />
                <Typography fontWeight="bold">Telefon</Typography>
              </Stack>
              <Link display="block" href="tel:+36304528948" color="inherit" fontWeight="medium" noWrap>
                06 30 452 8948
              </Link>
            </Box>
          </Stack>
          <Stack direction="row" spacing={2} py={2}>
            <IconButton href="https://www.instagram.com/browns_bs/" target="_blank" sx={iconButtonStyles}>
              <Instagram />
            </IconButton>
            <IconButton href="https://www.facebook.com/profile.php?id=100092273033825" target="_blank" sx={iconButtonStyles}>
              <Facebook />
            </IconButton>
          </Stack>
        </Stack>
      </Container>

      
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={3} py={1} sx={{ bgcolor: theme.palette.secondary.light }}>
        <NavLink to={Routes.PRIVAY_POLICY} style={{ textDecoration: 'none', color: "white" }}>Adatkezelési Nyilatkozatot</NavLink>
        <NavLink to={Routes.TERMS_AND_CONDITIONS} style={{ textDecoration: 'none', color: "white" }}>ÁSZF</NavLink>
      </Stack>

      <Stack alignItems="center" py={1} color="white" sx={{ bgcolor: theme.palette.secondary.main }}>
        <Typography variant="body2">
          © 2024 Browns Barber Shop. Minden jog fenntartva.
        </Typography>
      </Stack>
    </Box>
  )
}
