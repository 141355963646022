import { Typography, Stack, Box, IconButton, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import CustomModal from './CustomModal';
import dayjs from 'dayjs';
import { getFormattedDate } from '../../../utils/dateUtils';
import DeleteIcon from '@mui/icons-material/Delete';
import useGetBooking from '../api/useGetBooking';
import useCancelBookingDashboard from '../api/useCancelBookingDashboard';
import { alpha } from "@mui/material/styles";
import SelectedSupply from '../../../components/SelectedSupply';

const BookingDetailsModal = (props) => {
  const { show, handleClose, bookingId, showSnackBarMessage = () => null, reloadSignal = () => null } = props;
  const { getBooking, data: bookingData, error: getBookingError } = useGetBooking();
  const { cancelBookingDashboard, error: cancelBookingError } = useCancelBookingDashboard();
  const theme = useTheme();

  const supply = bookingData?.supply;

  useEffect(() => {
    if (cancelBookingError) {
      showSnackBarMessage(cancelBookingError.message);
    }
    if (getBookingError) {
      showSnackBarMessage(getBookingError.message);
    }
  }, [cancelBookingError, getBookingError]);

  useEffect(() => {
    if (bookingId && bookingId !== "") {
      getBooking(bookingId);
    }
  }, [bookingId]);

  const deleteBooking = async () => {
    if (bookingId && bookingId !== "") {
      await cancelBookingDashboard(bookingId);
      if (!cancelBookingError) {
        showSnackBarMessage("Sikeres törlés", "success");
        reloadSignal();
        handleClose();
      }
    }
  };

  return (
    <CustomModal
      open={show}
      onClose={handleClose}
      title="Foglalás részletei">
      <Stack spacing={2}>
        { supply&& <SelectedSupply supply={supply}/>}

        <Box>
          <Typography fontWeight="bold">{bookingData?.guest}</Typography>
          <Typography>Időpont: {getFormattedDate(dayjs(bookingData?.date))} {bookingData?.startTime} {bookingData && <>-</>} {bookingData?.endTime} </Typography>
        </Box>

        <Box alignSelf="center">
          <IconButton
            color="error"
            onClick={deleteBooking}
            sx={{
              color: theme.palette.error.main,
              '&:hover': {
                backgroundColor: alpha(theme.palette.error.light, 0.3),
              },
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Stack>
    </CustomModal>
  );
};

export default BookingDetailsModal;
