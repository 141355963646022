import { useState } from 'react';
import apiClient, { handleError } from '../../../services/apiClientService';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../routes/RouteConstants';

const GET_DAILY_SCHEDULES_ENDPOINT = '/v1/dashboard/getDailySchedules';

const useGetDailySchedules = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const getDailySchedules = async (requestData) => {
    setLoading(true);
    setError(null);

    try {
      const url = composeUrl(requestData);
      const response = await apiClient.get(url);
      setData(response.data);
    } catch (error) {
      handleError(error, setError, navigate, Routes.DASHBOARD);
    } finally {
      setLoading(false);
    }
  };

  return { getDailySchedules, loading, error, data };
};

const composeUrl = (requestData) => {
  let url = GET_DAILY_SCHEDULES_ENDPOINT + "?";
  if (requestData) {
    if (requestData.startDate) {
      url += `startDate=${requestData.startDate}&`;
    }

    if (requestData.supplyId) {
      url += `supplyId=${requestData.supplyId}`;
    }
  }

  return url;
}


export default useGetDailySchedules;
