import { TextField, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomModal from './CustomModal';
import CustomButton from '../../../components/CustomButton';
import useManualBooking from '../api/useManualBooking';
import SelectedSupply from '../../../components/SelectedSupply';

const ManualBookingModal = (props) => {
  const { show, handleClose, modalData, showSnackBarMessage = () => null, reloadSignal = () => null } = props;
  const { date = null, time = null, supply = null } = modalData || {};
  const { postManualBooking, error } = useManualBooking();
  const [name, setName] = useState("");

  useEffect(() => {
    if (error) {
      showSnackBarMessage(error.message);
    }
  }, [error]);

  const handleManualBooking = async () => {
    if (!name || name === "" || !supply || !supply.id || !date || !time) {
      return;
    }

    await postManualBooking({ supplyId: supply.id, date: date, startTime: time.startTime, name: name });

    if (!error) {
      showSnackBarMessage("Sikeres foglalás", "success");
      reloadSignal();
      handleClose();
    }
  };

  return (
    <CustomModal
      open={show}
      onClose={handleClose}
      title="Foglalás">
      <Stack spacing={3} alignItems="center">
        <TextField 
          id="appointment-modal-name-textfield"
          label="Név" 
          variant="outlined" 
          margin="normal"
          fullWidth={true} 
          value={name} 
          onChange={(event) => setName(event.target.value)} 
        />

        <CustomButton
          text="Foglalás" 
          onClick={handleManualBooking} 
        />
      </Stack>
    </CustomModal>
  );
};

export default ManualBookingModal;
