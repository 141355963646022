import React from 'react';
import { Typography } from '@mui/material';
import BorderedBox from './BorderedBox';

const SelectedSupply = ({ supply, fixedWidth = true, title }) => {
  return (
    <BorderedBox title={title} fixWidth={fixedWidth ? "130px" : undefined}>
      <Typography
        color="primary"
        fontWeight="bold"
        gutterBottom
      >
        {supply?.name}
      </Typography>

      <Typography variant="body1">
        {supply?.price} Ft
      </Typography>

      <Typography variant="body1">
        {supply?.duration} perc
      </Typography>
    </BorderedBox>
  );
};

export default SelectedSupply;
