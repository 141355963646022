import { useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom'; 
import { StorageKeys } from "../../../services/constants/StorageKeys";
import { storage } from "../../../services/storageService";
import Page from "../../../components/Page";
import { Routes } from "../../../routes/RouteConstants";
import Section from "../../../components/Section";
import Loading from "../../../components/Loading";

const SuccessfulLoginLayout = () => {
  const navigate = useNavigate();
  const { forceHeaderReRender } = useOutletContext();

  useEffect(() => {
    storage.set(StorageKeys.IS_USER_AUTHENTICATED, true);
  
    const targetRouteBeforLogin = storage.get(StorageKeys.TARGET_ROUTE_BEFORE_REDIRECT);
    storage.remove(StorageKeys.TARGET_ROUTE_BEFORE_REDIRECT);
  
    if (targetRouteBeforLogin) {
      navigate(targetRouteBeforLogin);
    } else {
      navigate(Routes.PROFILE);
    }

    forceHeaderReRender();
  }, []);

  return (
    <Page>
      <Section title="Sikeres hitelesítés" minWidth={{ xs: "70vw", sm: "60vw", md: "50vw", lg: "40vw", xl: "30vw" }}>
        <Loading />
      </Section>
    </Page>
  );
};

export default SuccessfulLoginLayout;
